<template>
  <v-select
    :items="allYears"
    v-model="yearModel"
    label="Année"
    density="compact"
    class="mt-5"
  ></v-select>
</template>

<script setup>
import { ref, watch } from "vue";
import { useUploadStore } from "@/store/upload";
const store = useUploadStore();
const { selectedYear, allYears } = store;
const yearModel = ref(selectedYear);
watch(yearModel, (newValue) => {
  store.setYear(newValue);
});
</script>
