<template>
  <v-btn
    class="mr-2"
    icon="mdi-file-excel-outline"
    @click="downloadExport"
    :loading="loading"
  ></v-btn>
</template>

<script setup>
import { ref } from "vue";
import { useUploadStore } from "@/store/upload";
import { storeToRefs } from "pinia";
import axios from "axios";

let loading = ref(false);

const store = useUploadStore();
const { year } = storeToRefs(store);

async function downloadExport() {
  loading.value = true;
  try {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/forms/exports?year=${year.value}`,
      responseType: "blob",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(new Blob([data]));
    link.setAttribute("download", `export.xlsx`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}
</script>