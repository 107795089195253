<template>
  <v-list v-if="currentUser">
    <v-list-item :title="currentUser.name" :subtitle="currentUser.email">
      <template v-slot:prepend>
        <v-avatar v-if="small" rounded="0" size="35">
          <v-img
            src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
            alt="John"
          ></v-img
        ></v-avatar>

        <v-avatar
          v-else
          :color="small ? '' : '#DA291C'"
          :size="small ? '28' : '36'"
          >{{ shortName }}</v-avatar
        >
      </template>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: ["small"],
  mounted() {
    this.currentUser = this.$keycloak.tokenParsed;
  },
  computed: {
    shortName: function () {
      return `${this.$keycloak.tokenParsed.given_name.substr(
        0,
        1
      )}${this.$keycloak.tokenParsed.family_name.substr(0, 1)}`;
    },
  },
  data: () => ({
    menu: false,
    currentUser: undefined,
  }),
};
</script>