<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card
          v-if="currentYear === year"
          @drop.prevent="onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          @click="$refs.file.click()"
          :class="{ 'grey lighten-2': dragover }"
          :ripple="false"
        >
          <input
            type="file"
            ref="file"
            v-show="false"
            @change="onDrop"
            id="fileInput"
            accept=".docx"
            name="file"
          />
          <v-card-text>
            <v-row
              class="d-flex flex-column"
              dense
              align="center"
              justify="center"
            >
              <v-icon
                :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                size="50"
                icon="mdi-cloud-upload"
                color="grey"
              >
              </v-icon>
              <p>
                Déposez vos fichiers ici ou cliquez pour sélectionner des
                fichiers
              </p>
            </v-row>
            <v-divider class="my-5" v-if="uploadedFiles.length"></v-divider>
            <v-list-item v-for="item in uploadedFiles" :key="item.name">
              <v-list-item-title>
                {{ item.name }}
                <span class="ml-3 text--secondary"> {{ item.size }} bytes</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.type }}
              </v-list-item-subtitle>

              <template v-slot:append>
                <v-btn
                  @click.stop="removeFile(item.name)"
                  icon="mdi-close-circle"
                  variant="text"
                />
              </template>

              <v-list-item-action> </v-list-item-action>
            </v-list-item>
            <v-divider v-if="uploadedFiles.length" class="my-5"></v-divider>
            <v-btn
              v-if="uploadedFiles.length"
              variant="outlined"
              block
              class="my-5"
              @click.stop="uploadFile"
              :loading="uploading"
              >Déposer le fichier</v-btn
            >
          </v-card-text>
        </v-card>
        <div v-if="!uploads.length" class="mt-3">
          Aucun fichier à afficher...
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-timeline side="end" density="compact">
          <v-timeline-item
            :size="upload.latest ? 'x-large' : 'small'"
            v-for="(upload, index) in uploads"
            :key="upload._id"
            :icon="upload.latest ? 'mdi-cloud-download' : ''"
            :dot-color="upload.latest ? 'success' : 'grey'"
            width="100%"
          >
            <template v-slot:icon>
              <v-icon
                :loading="downloading === index"
                v-if="upload.latest"
                @click="
                  downloadFile(
                    year,
                    'uploads',
                    upload.filename_download
                      ? upload.filename_download
                      : upload.filename,
                    index
                  )
                "
                icon="mdi-cloud-download"
                size="small"
              >
              </v-icon>
            </template>
            <v-card class="elevation-2">
              <v-card-title class="text-subtitle-1">
                {{ upload.employee.firstname }} {{ upload.employee.lastname }}
              </v-card-title>
              <v-card-text>
                <div>
                  Date :
                  {{ format(parseISO(upload.createdAt), "dd.MM.yyyy à HH:mm") }}
                </div>
                <div>Nom du fichier : {{ upload.filename }}</div>
                <div>Déposé par : {{ upload.createdBy.name }}</div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn text @click="snackbar.show = false">Fermer</v-btn></v-snackbar
    >
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useUploadStore } from "@/store/upload";
import { format, parseISO } from "date-fns";
import FileDownload from "js-file-download";
import axios from "axios";

const store = useUploadStore();
await store.loadUploads();
const { uploads, year, currentYear } = storeToRefs(store);
let dragover = ref(false);
let uploadedFiles = ref([]);
let uploading = ref(false);
let downloading = ref(null);

let snackbar = ref({
  show: false,
  text: "",
  color: "",
});

function removeFile(fileName) {
  document.getElementById("fileInput").value = "";
  const index = uploadedFiles.value.findIndex((file) => file.name === fileName);
  if (index > -1) uploadedFiles.value.splice(index, 1);
}

function onDrop(e) {
  dragover = false;
  e.preventDefault();
  if (uploadedFiles.value.length > 0) uploadedFiles.value = [];
  if (e.dataTransfer) {
    if (e.dataTransfer.files.length > 1) {
      snackbar.value = {
        show: true,
        text: "Vous ne pouvez pas uploader plus d'un fichier",
        color: "error",
      };
    } else {
      Array.from(e.dataTransfer.files).forEach((element) =>
        uploadedFiles.value.push(element)
      );
    }
  } else {
    Array.from(e.target.files).forEach((element) =>
      uploadedFiles.value.push(element)
    );
  }
}

async function uploadFile() {
  uploading.value = true;
  try {
    const formData = new FormData();
    formData.append("file", uploadedFiles.value[0]);
    const { data } = await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URI}/uploads?year=${year.value}`,
      data: formData,
      headers: {
        "content-Type": "multipart/form-data",
      },
    });
    uploadedFiles.value = [];

    if (uploads.value.find((u) => u.employee.idisa === data.employee.idisa)) {
      uploads.value.find(
        (u) => u.employee.idisa === data.employee.idisa
      ).latest = false;
    }
    uploads.value.unshift(data);
  } catch (e) {
    console.log(e);
  } finally {
    document.getElementById("fileInput").value = "";
    uploading.value = false;
  }
}
async function downloadFile(year, type, filename, index) {
  downloading.value = index;
  try {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/files/${year}/${type}/${encodeURI(
        filename
      )}`,
      responseType: "arraybuffer",
    });
    FileDownload(data, filename);
  } catch (error) {
    snackbar.value = {
      show: true,
      text: "Erreur de chargement",
      color: "error",
    };
  } finally {
    downloading.value = false;
  }
}
</script>
