<template>
  <Suspense>
    <dashboard-component />
    <template #fallback
      ><v-container
        ><v-row><v-col>Chargement...</v-col></v-row></v-container
      ></template
    >
  </Suspense>
</template>

<script>
import DashboardComponent from "@/components/DashboardComponent.vue";

export default {
  name: "DashboardView",
  components: {
    DashboardComponent,
  },
};
</script>
