// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { VDataTable } from "vuetify/labs/VDataTable";
import { fr } from "vuetify/locale";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify(
  {
    components: { VDataTable },
    locale: { locale: "fr", messages: { fr } },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);
