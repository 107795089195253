import { createRouter, createWebHistory } from "vue-router";
import UploadView from "@/views/UploadView.vue";
import AdminView from "@/views/AdminView.vue";
import DashboardView from "@/views/DashboardView.vue";
import DocumentsView from "@/views/DocumentsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
  },
  {
    path: "/uploads",
    name: "Uploads",
    component: UploadView,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
  },
  {
    path: "/documents",
    name: "Documents",
    component: DocumentsView,
  },
  {
    path: "/exports",
    name: "Export des données",
    component: AdminView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
