import { createApp, markRaw } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import { createPinia } from "pinia";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
loadFonts();

const tokenInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${app.config.globalProperties.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const init = { onLoad: "login-required" };
if (!isChrome) {
  init.checkLoginIframe = false;
}

const pinia = createPinia();

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(VueKeyCloak, {
    init,
    config: {
      realm: process.env.VUE_APP_KC_REALM,
      url: process.env.VUE_APP_KC_URL,
      clientId: process.env.VUE_APP_KC_CLIENT_ID,
    },
    onReady: (keycloak) => {
      if (
        !keycloak.hasResourceRole("admin", process.env.VUE_APP_KC_CLIENT_ID)
      ) {
        keycloak.logout();
      } else {
        pinia.use(({ store }) => {
          store.$router = markRaw(router);
          store.$keycloak = markRaw(keycloak);
        });
        tokenInterceptor();
        app.mount("#app");
      }
    },
  });
