<template>
  <v-app dark>
    <v-navigation-drawer expand-on-hover rail v-on:update:rail="toggleHover">
      <user-profile :small="small" />
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <v-list-item
          v-if="!$keycloak.hasResourceRole('superadmin')"
          prepend-icon="mdi-view-dashboard-outline"
          title="Dashboard"
          value="dashboard"
          to="/dashboard"
        ></v-list-item>
        <v-list-item
          v-if="!$keycloak.hasResourceRole('superadmin')"
          prepend-icon="mdi-cloud-upload-outline"
          title="Uploads"
          value="uploads"
          to="/uploads"
        ></v-list-item>
        <v-list-item
          v-if="$keycloak.hasResourceRole('superadmin')"
          prepend-icon="mdi-file-word-outline"
          title="Documents"
          value="documents"
          to="/documents"
        ></v-list-item>
        <v-list-item
          v-if="$keycloak.hasResourceRole('superadmin')"
          prepend-icon="mdi-file-excel-outline"
          title="Export des données"
          value="exports"
          to="/exports"
        ></v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list density="compact" nav>
          <v-list-item
            location="end"
            prepend-icon="mdi-logout"
            title="Se déconnecter"
            value="logout"
            @click="$keycloak.logoutFn()"
          ></v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar elevation="1">
      <v-app-bar-title
        >Entretien annuel - {{ router.currentRoute.value.name }} -
        {{ year }}</v-app-bar-title
      >
      <template v-slot:append>
        <download-export v-if="router.currentRoute.value.name === 'Dashboard'" />
        <select-component />
        <v-switch
          v-model="selectedTheme"
          false-icon="mdi-lightbulb-outline"
          true-icon="mdi-weather-night"
          inset
          class="mt-5 mr-4 ml-5"
          v-on:update:model-value="toggleTheme"
        ></v-switch>
      </template>
    </v-app-bar>
    <v-main class="ma-md-10 ma-sm-0">
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup>
import { onMounted, ref } from "vue";
import UserProfile from "@/components/UserProfile.vue";
import SelectComponent from "@/components/SelectComponent.vue";
import DownloadExport from "@/components/DownloadExport.vue";
import { useUploadStore } from "@/store/upload";
import { storeToRefs } from "pinia";
import { useTheme } from "vuetify";
import { useRouter } from "vue-router";
const router = useRouter();
const theme = useTheme();
let selectedTheme = ref(theme.global.current.value.dark);
function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
  selectedTheme.value = theme.global.current.value.dark;
}
const store = useUploadStore();
onMounted(() => {
  store.checkFirstRedirect();
});
const { year } = storeToRefs(store);
let small = ref(true);

function toggleHover(e) {
  small.value = e;
}
</script>