<template>
  <Suspense>
    <documents-component />
    <template #fallback
      ><v-container
        ><v-row><v-col>Chargement...</v-col></v-row></v-container
      ></template
    >
  </Suspense>
</template>

<script>
import DocumentsComponent from "@/components/DocumentsComponent.vue";

export default {
  name: "HomeView",

  components: {
    DocumentsComponent,
  },
};
</script>
