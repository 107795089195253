<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field label="Rechercher..." v-model="search"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pa-5" elevation="2">
          <v-data-table
            :items="filteredForms"
            :headers="headers"
            :items-per-page-options="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'Tous' },
            ]"
          >
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="item._id">
                <td>{{ item.raw.idisa }}</td>
                <td>{{ item.raw.lastname }}</td>
                <td>{{ item.raw.firstname }}</td>
                <td>{{ item.raw.role }}</td>
                <td>{{ item.raw.entity }}</td>
                <td v-if="item.raw.uploads.length">
                  {{
                    format(
                      parseISO(item.raw.uploads[0].createdAt),
                      "dd.MM.yyyy HH:mm"
                    )
                  }}
                </td>
                <td v-else>-</td>
                <td class="text-center">
                  <v-tooltip location="start">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        variant="text"
                        @click="
                          downloadFile(
                            year,
                            'documents',
                            item.raw.filename,
                            index
                          )
                        "
                        :loading="downloading === `documents-${index}`"
                        :disabled="downloading !== null"
                        icon="mdi-file-word-outline"
                      ></v-btn>
                    </template>
                    Formulaire de base
                  </v-tooltip>

                  <v-tooltip location="end">
                    <template
                      v-slot:activator="{ props }"
                      v-if="item.raw.uploads.length"
                    >
                      <v-btn
                        v-bind="props"
                        variant="text"
                        @click="
                          downloadFile(
                            year,
                            'uploads',
                            item.raw.uploads[0].filename_download
                              ? item.raw.uploads[0].filename_download
                              : item.raw.uploads[0].filename,
                            index
                          )
                        "
                        :loading="downloading === `uploads-${index}`"
                        :disabled="downloading !== null"
                        icon="mdi-file-check-outline"
                      ></v-btn
                    ></template>
                    Dernier fichier déposé
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" xs="12" class="text-h6"
        >Téléchargement des formulaires de base</v-col
      >
      <v-col v-for="(entity, index) in entities" :key="entity" cols="12" sm="4">
        <v-btn
          block
          @click="downloadFiles(year, entity, index)"
          :loading="downloading === `${entity}-${index}`"
          :disabled="downloading !== null"
          >{{ entity }}</v-btn
        ></v-col
      >
    </v-row>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}</v-snackbar
    >
  </v-container>
</template>

<script setup>
import { ref, onMounted, onUpdated, computed } from "vue";
import { useUploadStore } from "@/store/upload";
import { storeToRefs } from "pinia";
import { decomposeAccents } from "@/lib/utils";
import axios from "axios";
import FileDownload from "js-file-download";
import { format, parseISO } from "date-fns";

const store = useUploadStore();
await store.loadFormsAdmin();
const { forms, year } = storeToRefs(store);

let search = ref("");
const filteredForms = computed(() => {
  return forms.value.filter((form) => {
    return (
      form.firstname.toLowerCase().includes(search.value.toLowerCase()) ||
      form.lastname.toLowerCase().includes(search.value.toLowerCase()) ||
      form.idisa.toLowerCase().includes(search.value.toLowerCase()) ||
      form.entity.toLowerCase().includes(search.value.toLowerCase()) ||
      form.role.toLowerCase().includes(search.value.toLowerCase())
    );
  });
});
onMounted(() => {
  store.checkSuperAdminRights();
});
onUpdated(() => {
  store.checkSuperAdminRights();
});
let snackbar = ref({
  show: false,
  text: "",
  color: "",
});
let headers = ref([
  { title: "IDISA", value: "idisa" },
  { title: "Nom", value: "lastname" },
  { title: "Prénom", value: "firstname" },
  { title: "Fonction", value: "role" },
  { title: "Entité", value: "entity" },
  { title: "Dernier chargement", value: "uploads[0].createdAt" },
  {
    title: "Téléchargements",
    value: "actions",
    sortable: false,
    align: "center",
  },
]);

let entities = ref([
  "COMEM+",
  "EC+G",
  "FORMATION DE BASE",
  "HEG",
  "TIC",
  "TIN",
]);

let downloading = ref(null);

async function downloadFile(year, type, filename, index) {
  downloading.value = `${type}-${index}`;
  try {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/admin/files/${year}/${type}/${
        type === "documents" ? decomposeAccents(filename) : encodeURI(filename)
      }`,
      responseType: "arraybuffer",
    });
    FileDownload(data, filename);
  } catch (error) {
    snackbar.value = {
      show: true,
      text: "Erreur de chargement",
      color: "error",
    };
  } finally {
    downloading.value = null;
  }
}

async function downloadFiles(year, entity, index) {
  downloading.value = `${entity}-${index}`;
  try {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/admin/zips/${year}/${entity}`,
      responseType: "arraybuffer",
    });
    FileDownload(data, entity + ".zip");
  } catch (error) {
    snackbar.value = {
      show: true,
      text: "Erreur de chargement",
      color: "error",
    };
  } finally {
    downloading.value = null;
  }
}
</script>