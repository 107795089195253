<template>
  <v-container>
    <v-row>
      <v-col v-for="upload in exports" :key="`${upload.name}`"
        ><v-btn outlined block @click="downloadExport(upload.name)"
          >{{ upload.name }} ({{ upload.count
          }}<span v-if="upload.forms">/{{ upload.forms }}</span
          >)</v-btn
        ></v-col
      >
    </v-row>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}</v-snackbar
    >
  </v-container>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted, onUpdated } from "vue";
import { useUploadStore } from "@/store/upload";
import { storeToRefs } from "pinia";
const store = useUploadStore();
const { year, exports } = storeToRefs(store);
await store.loadExportsLength();
onMounted(() => {
  store.checkSuperAdminRights();
});
onUpdated(() => {
  store.checkSuperAdminRights();
});
let snackbar = ref({
  show: false,
  text: "",
  color: "",
});
async function downloadExport(department) {
  try {
    const { data } = await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URI}/admin/uploads?year=${year.value}`,
      data: { department },
      responseType: "blob",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(new Blob([data]));
    link.setAttribute("download", `${department}.xlsx`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    this.snackbar = {
      show: true,
      text: error,
      color: "error",
    };
    console.log(error);
  }
}
</script>
